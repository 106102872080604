import { atomWithStorage } from "jotai/utils";
import { atom } from "jotai";

import { BrowserUserDetails, Toast } from "./Interfaces";
import firebase from "firebase/compat/app";

export const isAuthenticatedAtom = atomWithStorage("isAuthenticated", false);
export const showSidebarAtom = atomWithStorage("showSidebar", false);
export const reduceSidebarAtom = atomWithStorage("reduceSidebar", false);

export const toastDetailsAtom = atom<Toast>({
  open: false,
  message: "A sample message",
  severity: "info",
  color: "info",
});

export const userDetails = atomWithStorage<BrowserUserDetails>("userDetails", {
  school: "",
  email: "",
  username: "",
  avatar: "",
  id: "",
  major: "",
  displayName: "",
});

export const openPaymentRequestDialog = atom<boolean>(false);

export const activeChatQuestionId = atomWithStorage("activeChatQuestionId", "");
export const activeChatScholarUsername = atomWithStorage(
  "activeChatScholarUsername",
  ""
);
export const activeChatScholarAvatar = atomWithStorage(
  "activeChatScholarAvatar",
  ""
);
export const activeChatDueDate =
  atomWithStorage<firebase.firestore.Timestamp | null>(
    "activeChateDueDate",
    null
  );

export const supportPhoneNumber = atom<string>("+1 (440) 536-3775");
export const supportPhoneNumberWithoutFormating = atom<number>(14405363775);
export const supportEmail = atom<string>("support@scholars.guide");
