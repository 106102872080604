import React, { Suspense } from "react";
import { useAtom } from "jotai";
import { Routes, Route } from "react-router-dom";
import {
  isAuthenticatedAtom,
  showSidebarAtom,
  reduceSidebarAtom,
  userDetails,
} from "src/Lib/State";
import "./Routes.css";

import Container from "@mui/material/Container";

const Nav = React.lazy(() => import("../Components/Nav"));
const Sidebar = React.lazy(() => import("../Components/Sidebar"));
const Toast = React.lazy(() => import("../Components/Toast"));
const PaymentRequest = React.lazy(() => import("../Components/PaymentRequest"));

const Loading = React.lazy(() => import("../Pages/Loading"));
const Signin = React.lazy(() => import("../Pages/Signin"));
const Username = React.lazy(() => import("../Pages/Username"));
const Jobs = React.lazy(() => import("../Pages/Jobs"));
const JobDetails = React.lazy(() => import("../Pages/JobDetails"));
const WriterProfile = React.lazy(() => import("../Pages/WriterProfile"));
const Support = React.lazy(() => import("../Pages/Support"));
const Account = React.lazy(() => import("../Pages/Account"));
const Payments = React.lazy(() => import("../Pages/Payments"));
const Home = React.lazy(() => import("../Pages/Home"));
const Chat = React.lazy(() => import("../Pages/Chat"));
const Alerts = React.lazy(() => import("../Pages/Alerts"));

function AppRoutes() {
  const [isAuthenticated] = useAtom(isAuthenticatedAtom);
  const [showSidebar, setShowSidebar] = useAtom(showSidebarAtom);
  const [reduceSidebar, setReduceSidebar] = useAtom(reduceSidebarAtom);
  const [storedUserDetails] = useAtom(userDetails);

  return (
    <Suspense>
      <Suspense fallback={<Loading />}>
        {isAuthenticated ? (
          <div className={`grid-container`}>
            <nav className="gridHeader">
              <Nav
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
                reduceSidebar={reduceSidebar}
                setReduceSidebar={setReduceSidebar}
              />
            </nav>
            <aside
              className={`${showSidebar ? "active aside" : "aside"} ${
                reduceSidebar ? "reduceWidth" : ""
              } `}
            >
              <Sidebar
                setShowSidebar={setShowSidebar}
                reduceSidebar={reduceSidebar}
                setReduceSidebar={setReduceSidebar}
                showSidebar={showSidebar}
              />
            </aside>
            <main className="main">
              <Container
                maxWidth="xl"
                className="mainContentContainer"
                style={{ flex: 1 }}
              >
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/jobs" element={<Jobs />} />
                  <Route
                    path="/jobs/job-details/:id"
                    element={<JobDetails />}
                  />
                  <Route path="/profile" element={<WriterProfile />} />
                  <Route path="/support" element={<Support />} />
                  <Route path="/account" element={<Account />} />
                  <Route path="/payments" element={<Payments />} />
                  <Route path="/talk-with-scholars" element={<Chat />} />
                  <Route
                    path="/alerts"
                    element={<Alerts writerId={storedUserDetails.id} />}
                  />
                </Routes>
                <PaymentRequest />
              </Container>
            </main>
          </div>
        ) : (
          <Routes>
            <Route path="/signin" element={<Signin />} />
            <Route path="/username" element={<Username />} />
            <Route path="*" element={<Signin />} />
          </Routes>
        )}

        <Toast />
      </Suspense>
    </Suspense>
  );
}

export default AppRoutes;
